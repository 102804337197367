<script>
  import { formatCurrency } from "@core/filters";
  import { getMinMax } from "@core/helpers/getMinMax";
  import LTooltip from "../Tooltip/LTooltip.vue";

  /**
     * Rate
     * @typedef {Object} Rate
     * @property {number} writeOff - write off.
  */

  /**
     * MinMax
     * @typedef {Object} MinMax
     * @property {number} min
     * @property {number} max
  */

  /**
   * @param {Rate[]} rates
   * @return {MinMax}
  */
  const getRatesRange = (rates) => {
    const writeOffValues = rates.map(({ payout }) => payout);
    return getMinMax(writeOffValues);
  };

  export default {
    name: "AdvertiserRateOutput",

    render (h, context) {
      const {
        props: {
          isShowCommissions,
          rates,
          payout,
          withoutArrow
        },
        parent
      } = context;
      const children = [];
      const commonTooltip = { position: "is-top", type: "is-dark", withoutArrow };

      if (payout != null) {
        const label = parent.$t("admin.offers.table.tooltips.writeOff");
        const tooltip = h(LTooltip, { props: { ...commonTooltip, label, active: isShowCommissions } }, formatCurrency(payout.value, payout.currency));
        children.push(tooltip);
      }

      if (rates?.length) {
        const { min, max } = getRatesRange(rates);
        let tooltips = [];

        if (min != null && max != null && max !== min) {
          const minLabel = parent.$t("admin.offers.table.tooltips.minWriteOff");
          const maxLabel = parent.$t("admin.offers.table.tooltips.maxWriteOff");
          const minTooltip = h(LTooltip, { props: { ...commonTooltip, label: minLabel, active: isShowCommissions } }, formatCurrency(min.value, min.currency));
          const maxTooltip = h(LTooltip, { props: { ...commonTooltip, label: maxLabel, active: isShowCommissions } }, formatCurrency(max.value, max.currency));
          tooltips = [minTooltip, " - ", maxTooltip];
        } else if (min != null || max != null) {
          const label = parent.$t("admin.offers.table.tooltips.rate");
          const rateTooltip = h(LTooltip, { props: { ...commonTooltip, label, active: isShowCommissions } }, formatCurrency(
            min?.value ?? max?.value,
            min?.currency ?? max?.currency
          ));
          tooltips = [rateTooltip];
        }

        children.push(" (", ...tooltips, ")");
      }

      return h("span", children);
    },

    functional: true,

    props: {
      user: {
        type: Object,
        default: null
      },

      payout: {
        type: Object,
        default: null
      },

      rates: {
        type: Array,
        default: null
      },

      withoutArrow: {
        type: Boolean,
        default: false
      },

      isShowCommissions: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style scoped>

</style>
